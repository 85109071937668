/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Stack,
  MybpwContentWrapper,
  useDisclosure,
  Center,
  MybpwSpinner,
  MybpwAlertBox,
  Button,
  Box,
  color
} from '@customerapps/react-components';

import './index.css';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Parser } from 'html-to-react';
import OEmbedContainer from 'react-oembed-container';
import useStaticSiteApi from '../../api/staticsite';
import Gallery from '../../components/common/gallery/Gallery';
import LangSelectModal from '../../components/common/LangSelectModal';
import useStaticFileHelpers from '../../hooks/useStaticFileHelpers';
import { DocRef } from '../../types/staticSites/staticSite';
import StaticSiteHeader from '../../components/spareparts/StaticSiteHeader';
import StaticSiteCollapsableSection from '../../components/spareparts/StaticSiteCollapsableSection';
import PdfDocumentSlider from '../../components/common/pdfDocuments/PdfDocumentSlider';
import LangLinkModal from '../../components/common/LangLinkModal';
import { ArticleData } from '../../types/staticSites/article';

/**
 * The main container for the qr code spareparts details
 * Handles fetching sparepart details data from the backend and manages sparepart details state
 * Composes the sparepart details page from other components
 */
export default function StaticSites(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const locState = state as { prev?: string };
  const { t, i18n } = useTranslation();
  const { staticArticle } = useParams<{ staticArticle: string }>();
  const { getFullSvgPath } = useStaticFileHelpers();
  const {
    isOpen: isOpenLangModal,
    onOpen: onOpenLangModal,
    onClose: onCloseLangModal
  } = useDisclosure();
  const {
    isOpen: isOpenPdfDocumentModal,
    onOpen: onOpenPdfDocumentModal,
    onClose: onClosePdfDocumentModal
  } = useDisclosure();

  const { getStaticArticles } = useStaticSiteApi();

  const downloadMatNum = useRef('');
  const downloadType = useRef('');
  const pdfDocumentRef = useRef<DocRef>();

  const {
    data: staticSite,
    error,
    isLoading
  } = useQuery(['headlessCMS', staticArticle, i18n.language], () => {
    const staticArticleId = staticArticle || '';
    return getStaticArticles(staticArticleId, i18n.language);
  });

  function DocumentClickedHandler(pdfDocument: DocRef): void {
    pdfDocumentRef.current = pdfDocument;
    onOpenPdfDocumentModal();
  }

  function goBackHandler(): void {
    navigate(-1);
  }

  function exportPdfHandler(selectedLang: string): void {
    const matNum = downloadMatNum.current;
    // pdfDownlaod({ num: matNum, lang: selectedLang });
  }

  function performExportHandler(selectedLang: string): void {
    const type = downloadType.current;
    if (type === 'pdf') {
      exportPdfHandler(selectedLang);
    }
  }

  const title = locState?.prev || t('spareparts.titles.overview');

  function hasImages(images?: ArticleData[]): boolean {
    return images
      ? images.filter((item) => {
          return item.attributes.ProductPicture.data != null;
        }).length > 0
      : false;
  }

  function mapImageUrl(images?: ArticleData[]): string[] {
    return images
      ? images
          .map((item) => item.attributes.ProductPicture.data)
          .flat()
          .map((pic) => pic.attributes.url)
          .map(getFullSvgPath)
      : [];
  }

  return (
    <>
      <LangSelectModal
        isOpen={isOpenLangModal}
        onClose={onCloseLangModal}
        title={t('spareparts.titles.exportPdfModal')}
        onSelect={(selecedLang) => performExportHandler(selecedLang)}
      />
      {pdfDocumentRef.current && (
        <LangLinkModal
          isOpen={isOpenPdfDocumentModal}
          onClose={onClosePdfDocumentModal}
          links={pdfDocumentRef.current.links}
          title={t('spareparts.titles.downloadManualModal')}
        />
      )}

      {!isLoading &&
        !error &&
        staticSite &&
        staticSite.data &&
        staticSite.data[0] && (
          <MybpwContentWrapper
            title={staticSite.data[0].attributes.Title || ''}
            titleNoOfLines={6}
          >
            <Stack spacing="20" w="full">
              <Stack spacing="20" w="full">
                <Stack spacing={8} w="full">
                  {staticSite.data[0].attributes.SubHeader.map((subHeader) => (
                    <StaticSiteHeader
                      title={subHeader.SubTitle}
                      desc={/* staticSite.shortName || */ ''}
                    />
                  ))}

                  {hasImages(staticSite.data) && (
                    <Gallery imageUrls={mapImageUrl(staticSite.data)} />
                  )}

                  {staticSite.data[0].attributes.Content.map((content) => (
                    <StaticSiteCollapsableSection
                      key={content.__component + content.id}
                      name={content.Title}
                      defaultOpen={false}
                    >
                      {content.__component === 'content.document-content' &&
                        content.Document &&
                        content.Document.data && (
                          <PdfDocumentSlider
                            pdfDocuments={
                              content.Document?.data.map<DocRef>(
                                (doc, index) => ({
                                  id: doc.attributes.hash,
                                  links: {
                                    [i18n.language]: `${process.env.REACT_APP_HEADLESS_CMS_URL}${doc.attributes.url}`
                                  },
                                  thumb:
                                    content.DocumentThumbnail?.data?.[index]
                                      ?.attributes.url
                                })
                              ) || []
                            }
                            onDocumentClicked={DocumentClickedHandler}
                          />
                        )}
                      {content.__component === 'content.text-content' && (
                        <Stack spacing="8">
                          <OEmbedContainer markup={content.Text || ''}>
                            <Box
                              className="textContent"
                              dangerouslySetInnerHTML={{
                                __html: content?.Text || '' // DOMPurify.sanitize(content?.Text || '')
                              }}
                            />
                          </OEmbedContainer>
                        </Stack>
                      )}
                    </StaticSiteCollapsableSection>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </MybpwContentWrapper>
        )}
      {!isLoading && error && (error as Error).message === 'NOT_FOUND' && (
        <MybpwContentWrapper title={title || ''} onGoBack={goBackHandler}>
          <MybpwAlertBox
            message={t('notifications.error.noSparepartForMatNum')}
            status="error"
          />
        </MybpwContentWrapper>
      )}
      {isLoading && (
        <Center w="full" h="100vh">
          <MybpwSpinner />
        </Center>
      )}
    </>
  );
}
