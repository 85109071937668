/* eslint-disable no-param-reassign */
import {
  ApplicationInsights,
  ITelemetryItem
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

/**
 * Application insights config
 */
const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_KEY,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin]
  }
});
appInsights.loadAppInsights();
appInsights.trackPageView();
const telemetryInitializer = (envelope: ITelemetryItem) => {
  if (envelope) {
    if (envelope.data) {
      envelope.data.BpwApplicationGroup = process.env.REACT_APP_BPW_APP_GROUP;
      envelope.data.BpwApplication = process.env.REACT_APP_HEADLESS_CMS_APP;
      envelope.data.Component = `${process.env.REACT_APP_HEADLESS_CMS_APP}HeadlessCMS`;
    }
  }
};
appInsights.addTelemetryInitializer(telemetryInitializer);
export { reactPlugin, appInsights };
