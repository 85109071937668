import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

import './gallery.css';
import {
  Box,
  Center,
  SlideFade,
  Tag,
  Text,
  VStack
} from '@customerapps/react-components';
import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type GalleryProps = {
  imageUrls: string[];
};

/**
 * Image Slider component with light box functionallity if a user clicks a image
 * Used on the spareparts detail pages
 */
export default function Gallery(props: GalleryProps): JSX.Element {
  const galleryRef = useRef<{ toggleFullScreen: () => void } & ImageGallery>(
    null
  );
  const { t } = useTranslation();
  const [galleryHovered, setGalleryHovered] = useState(false);
  const [images, setImages] = useState<ReactImageGalleryItem[]>();

  const { imageUrls } = props;

  // const images = imageUrls.map((url) => ({
  //   fullscreen: url,
  //   original: url.replace('/uploads/', '/uploads/small_')
  // }));

  useEffect(() => {
    const getImageUrls = async (
      imageUrls: string[]
    ): Promise<ReactImageGalleryItem[]> => {
      const checkImageUrl = async (url: string) => {
        try {
          const response = await fetch(url);
          return response.ok ? url : null;
        } catch (error) {
          return null;
        }
      };
      const images = await Promise.all(
        imageUrls.map(async (url) => {
          const validUrl = await checkImageUrl(
            url.replace('/uploads/', '/uploads/small_')
          );
          return {
            fullscreen: url,
            original: validUrl || url
          };
        })
      );
      return images;
    };

    getImageUrls(imageUrls).then((images) => setImages(images));
  }, [imageUrls]);

  return (
    <Box
      borderColor="gray.100"
      borderWidth="2px"
      display="inline-block"
      borderRadius="lg"
      overflow="hidden"
      width={{ base: '100%', xl: '500px' }}
      height={{ base: '400px', xl: '500px' }}
      position="relative"
      onMouseEnter={() => setGalleryHovered(true)}
      onMouseLeave={() => setGalleryHovered(false)}
    >
      {imageUrls && imageUrls.length > 0 && (
        <Box
          position="absolute"
          top="2"
          left="2"
          zIndex="10"
          display={{ base: 'none', xl: 'block' }}
        >
          <SlideFade in={galleryHovered} offsetX="-20px" offsetY="2">
            <Tag>{t('spareparts.buttons.fullscreen')}</Tag>
          </SlideFade>
        </Box>
      )}
      {imageUrls && imageUrls.length > 0 ? (
        <ImageGallery
          items={images || []}
          showPlayButton={false}
          showBullets={imageUrls.length < 5}
          showIndex={imageUrls.length >= 5}
          useBrowserFullscreen={false}
          ref={galleryRef}
          onClick={() => {
            if (galleryRef && galleryRef.current) {
              galleryRef.current.toggleFullScreen();
            }
          }}
        />
      ) : (
        <Center h="full" w="full">
          <VStack color="gray.200" spacing="4">
            <FontAwesomeIcon size="2x" icon="image" />
            <Text color="gray.700">{t('spareparts.texts.noImages')}</Text>
          </VStack>
        </Center>
      )}
    </Box>
  );
}
