import { Box } from '@customerapps/react-components';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import StaticSites from '../containers/StaticSite';

export default function StaticSiteView(): JSX.Element {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>
          {t('commons.seo.appName')} | {t('commons.seo.spareparts.title')}{' '}
        </title>
      </Helmet>
      <Box px={{ base: '2', sm: '8' }} py="12" w="full">
        <StaticSites />
      </Box>
    </>
  );
}
