/* eslint-disable import/no-unresolved */
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@customerapps/react-components';
import { DocRef } from '../../../types/staticSites/staticSite';
import PdfDocumentCard from './PdfDocumentCard';

import 'swiper/css';
import 'swiper/css/navigation';
import './pdfDocuments.css';

type PdfDocumentSliderProps = {
  pdfDocuments: DocRef[];
  onDocumentClicked: (manual: DocRef) => void;
};

/**
 * Carousel component for multiple manual cards
 */
export default function PdfDocumentSlider(
  props: PdfDocumentSliderProps
): JSX.Element {
  const { pdfDocuments: documents, onDocumentClicked } = props;
  return (
    <Box w="full">
      <Swiper
        modules={[Navigation]}
        slidesPerView="auto"
        centeredSlides={true}
        navigation
      >
        {documents.map((document: DocRef) => (
          <SwiperSlide key={document.id}>
            <Box>
              <PdfDocumentCard
                document={document}
                onDocumentClicked={onDocumentClicked}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
