import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
  MybpwLangLinkGrid
} from '@customerapps/react-components';

type LangLinkModalProps = {
  title: string;
  isOpen?: boolean;
  links: { [key: string]: string };
  onClose: () => void;
};

/**
 * Modal component which displays a grid of flages to select a language
 * Flags are real link elements that trigger an download or navigation when clicked
 */
export default function LangLinkModal(props: LangLinkModalProps): JSX.Element {
  const { title, links, isOpen, onClose } = props;

  return (
    <Modal isOpen={!!isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box onClick={onClose}>
            <MybpwLangLinkGrid
              includeLangCodes={Object.keys(links).map((lang) => lang)}
              links={links}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
