import {
  useMybpwApiUtils,
  useMybpwLogger
} from '@customerapps/react-components';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { useContext } from 'react';
import qs from 'qs';
import StaticSite, { DocRef } from '../types/staticSites/staticSite';
import { Article as StaticArticle } from '../types/staticSites/article';

type StaticSiteApi = {
  getStaticArticles: (
    id: string,
    lang: string
  ) => Promise<StaticArticle | null>;
};

/**
 * Hook with api functions to access the qr code api
 */

export default function useStaticSiteApi(): StaticSiteApi {
  const { trackError, getCorrelationId } = useMybpwLogger(
    process.env.REACT_APP_HEADLESS_CMS_APP || ''
  );

  async function getStaticArticles(
    id: string,
    lang: string
  ): Promise<StaticArticle | null> {
    const articleUrl = `/api/articles`;
    const corId = getCorrelationId();

    const config: AxiosRequestConfig = {
      headers: {
        Authorization:
          process.env.REACT_APP_HEADLESS_CMS_ANONYMOUS_API_TOKEN || ''
      },
      params: {
        filters: {
          URLSuffix: {
            $eq: id
          }
        },
        populate: [
          'attributes',
          'SubHeader',
          'Content',
          'ProductPicture',
          'Content.Document',
          'Content.DocumentThumbnail'
        ],
        locale: lang
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    };

    try {
      const response = await axios.get<StaticArticle>(articleUrl, config);
      const details = response.data;
      if (details.data.length) {
        return details;
      }

      // We didn't find data for the requested langauge. Fallback to german.
      config.params.locale = 'en';
      const responseDe = await axios.get<StaticArticle>(articleUrl, config);
      return responseDe.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error?.response?.status === 404 || error?.response?.status === 400) {
        throw new Error('NOT_FOUND');
      }
      trackError(
        corId,
        'API_CALL',
        'Fetch material numbers from back-end',
        articleUrl,
        '',
        JSON.stringify(e)
      );
    }
    return null;
  }

  return {
    getStaticArticles
  };
}
