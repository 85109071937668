import { Box, Heading, HStack, Stack } from '@customerapps/react-components';

type StaticSiteHeaderProps = {
  title: string;
  desc?: string;
};

/**
 * Sparepart details header
 */
export default function StaticSiteHeader(
  props: StaticSiteHeaderProps
): JSX.Element {
  const { title, desc } = props;

  return (
    <HStack
      w="full"
      color="primary.500"
      position="relative"
      spacing="0"
      align="start"
    >
      <Stack w="full">
        <Heading noOfLines={6} fontSize={{ base: 'lg', md: '2xl', lg: '3xl' }}>
          {title}
        </Heading>
        {desc && (
          <Heading
            w="full"
            fontSize={{ base: 'md', md: 'lg', lg: 'xl' }}
            lineHeight={1.3}
          >
            <Box dangerouslySetInnerHTML={{ __html: desc }} />
          </Heading>
        )}
      </Stack>
    </HStack>
  );
}
