import {
  MybpwLangOption,
  availableLangs,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  MybpwLangSelectionGrid
} from '@customerapps/react-components';

type LangSelectModalProps = {
  title: string;
  isOpen?: boolean;
  includeLangCodes?: string[];
  onClose: () => void;
  onSelect: (lang: string) => void;
};

/**
 * Modal component which displays a grid of flages to select a language.
 * Flags are buttons that fire an event when clicked.
 */
export default function LangSelectModal(
  props: LangSelectModalProps
): JSX.Element {
  const { title, isOpen, includeLangCodes, onClose, onSelect } = props;

  let langs: MybpwLangOption[] = availableLangs;

  if (includeLangCodes && includeLangCodes.length) {
    langs = langs.filter((opt) => includeLangCodes.includes(opt.code));
  }

  function selectHandler(code: string): void {
    onSelect(code);
    onClose();
  }

  return (
    <Modal isOpen={!!isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <MybpwLangSelectionGrid
            selectedLang=""
            includeLangCodes={langs.map((lang) => lang.code)}
            onSelect={(lang) => selectHandler(lang)}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
