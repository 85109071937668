import { MybpwThemeProvider } from '@customerapps/react-components';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { QueryClient, QueryClientProvider } from 'react-query';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faBomb } from '@fortawesome/free-solid-svg-icons/faBomb';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons/faFolderOpen';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons/faBookOpen';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';

import PortalLayout from './containers/PortalLayout';
import { reactPlugin } from './config/appInsights';
import Routing from './Routing';

library.add(
  faImage,
  faChevronDown,
  faSortDown,
  faSortUp,
  faSort,
  faDownload,
  faBomb,
  faFolderOpen,
  faBan,
  faBookOpen,
  faCheck,
  faImage
);

export default function App(): JSX.Element {
  const queryClient = new QueryClient();

  return (
    <Suspense fallback="">
      <AppInsightsContext.Provider value={reactPlugin}>
        <BrowserRouter basename="/qr">
          <MybpwThemeProvider>
            <QueryClientProvider client={queryClient}>
              <PortalLayout>
                <Routing />
              </PortalLayout>
            </QueryClientProvider>
          </MybpwThemeProvider>
        </BrowserRouter>
      </AppInsightsContext.Provider>
    </Suspense>
  );
}
