import {
  availableLangs,
  defaultLang,
  MybpwBaseLayout,
  MybpwCompanies,
  MybpwFooter,
  MybpwHeader,
  MybpwLangSwitch,
  useMybpwLang
} from '@customerapps/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

/**
 * Provides the layout for all protected portal pages
 */
export default function PortalLayout(
  props: React.PropsWithChildren<React.ReactNode>
): JSX.Element {
  const { t } = useTranslation();
  const { getCurrentLang, langChangedHandler } = useMybpwLang(
    availableLangs,
    defaultLang
  );

  const links = {
    bpw: t('companies.bpw'),
    ermax: t('companies.ermax'),
    hbn: t('companies.hbn'),
    hestal: t('companies.hestal'),
    idem: t('companies.idem')
  };
  const langSwitcher = (
    <MybpwLangSwitch
      langOptions={availableLangs}
      currentLang={getCurrentLang()}
      onChange={langChangedHandler}
      rightIcon={<FontAwesomeIcon icon="chevron-down" />}
    />
  );
  const header = <MybpwHeader right={langSwitcher} />;
  const footerSection = (
    <>
      <MybpwCompanies title={t('companies.title')} links={links} />
      <MybpwFooter
        homeLink={t('footer.home.link')}
        homeLinkText={t('footer.home.text')}
        imprintLink={t('footer.imprint.link')}
        imprintLinkText={t('footer.imprint.text')}
        privacyLink={t('footer.privacy.link')}
        privacyLinkText={t('footer.privacy.text')}
      />
    </>
  );

  return (
    <MybpwBaseLayout
      header={header}
      footer={footerSection}
      sidebarIsOpen={false}
      onSidebarClose={() => null}
    >
      {props.children}
    </MybpwBaseLayout>
  );
}
