import { MybpwErrorPage } from '@customerapps/react-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ErrorProps = {
  error: string;
};

/**
 * The error pages
 */
export default function Error(props: ErrorProps): JSX.Element {
  const { t } = useTranslation();

  const { error } = props;

  return (
    <>
      {error === '500' && (
        <MybpwErrorPage
          icon={<FontAwesomeIcon size="2x" icon="bomb" />}
          title={t('commons.errors.server')}
          corIdLabel={t('commons.labels.corId')}
        />
      )}
      {error === '401' && (
        <MybpwErrorPage
          icon={<FontAwesomeIcon size="2x" icon="ban" />}
          title={t('commons.errors.unauthorized')}
          corIdLabel={t('commons.labels.corId')}
        />
      )}
      {error === '403' && (
        <MybpwErrorPage
          icon={<FontAwesomeIcon size="2x" icon="ban" />}
          title={t('commons.errors.forbidden')}
          corIdLabel={t('commons.labels.corId')}
        />
      )}
      {error === '404' && (
        <MybpwErrorPage
          icon={<FontAwesomeIcon size="2x" icon="folder-open" />}
          title={t('commons.errors.notFound')}
          corIdLabel={t('commons.labels.corId')}
        />
      )}
    </>
  );
}
