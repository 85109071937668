import { Box, Center, Image } from '@customerapps/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DocRef } from '../../../types/staticSites/staticSite';

type PdfDocumentCardProps = {
  document: DocRef;
  onDocumentClicked: (document: DocRef) => void;
};

/**
 * Manual card component which displays an manual image and opens a language selection on click
 */
export default function PdfDocumentCard(
  props: PdfDocumentCardProps
): JSX.Element {
  return (
    <Box height="500px" id={`${props.document.id}`} pt="8" pb="8">
      <Box
        onClick={() => props.onDocumentClicked(props.document)}
        h="full"
        maxW="300px"
        m="auto"
        borderRadius="md"
        position="relative"
        overflow="hidden"
        cursor="pointer"
        borderWidth="2px"
        borderColor="gray.50"
        boxShadow="xl"
      >
        {props.document.thumb && (
          <Image
            src={`${process.env.REACT_APP_HEADLESS_CMS_URL}${props.document.thumb}`}
            height="full"
            width="full"
            objectFit="cover"
          />
        )}
        {!props.document.thumb && (
          <Center h="full" color="gray.200">
            <FontAwesomeIcon icon="image" size="3x" />
          </Center>
        )}
      </Box>
    </Box>
  );
}
