import {
  useBoolean,
  Box,
  Collapse,
  Stack,
  HStack,
  Heading
} from '@customerapps/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';

type StaticSiteCollapsableSectionProps = {
  name: string;
  defaultOpen?: boolean;
};

/**
 * Layout of the static site
 */
export default function StaticSiteCollapsableSection(
  props: React.PropsWithChildren<StaticSiteCollapsableSectionProps>
): JSX.Element {
  const { name, defaultOpen, children } = props;
  const [open, setOpen] = useBoolean(false);

  useEffect(() => {
    if (defaultOpen) {
      setOpen.on();
    }
  }, [defaultOpen, setOpen]);

  return (
    <Stack spacing={0} w="full">
      <HStack
        w="full"
        color="primary.500"
        position="relative"
        spacing="0"
        _hover={{ color: 'primary.700', cursor: 'pointer' }}
        onClick={setOpen.toggle}
      >
        <Box
          mr="2"
          mt="1"
          transition="all 300ms"
          transform={open ? 'rotate(0deg)' : 'rotate(-90deg)'}
        >
          <FontAwesomeIcon icon="chevron-down" size="2x" />
        </Box>
        <Heading noOfLines={2} fontSize={{ base: 'lg', md: '2xl', lg: '3xl' }}>
          {name}
        </Heading>
      </HStack>
      <Collapse in={open}>
        <Stack spacing="8" w="full" pt={8}>
          {children}
        </Stack>
      </Collapse>
    </Stack>
  );
}
