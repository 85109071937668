import { Route, Routes } from 'react-router-dom';
import Error from './views/Error';
import StaticSiteView from './views/StaticSiteView';

export default function Routing(): JSX.Element {
  return (
    <Routes>
      <Route path="/error/:corId" element={<Error error="500" />} />
      <Route path="/unauthorized/:corId" element={<Error error="401" />} />
      <Route path="/forbidden/:corId" element={<Error error="403" />} />
      <Route path="/notFound/:corId" element={<Error error="404" />} />
      <Route path="/:staticArticle" element={<StaticSiteView />} />
      <Route path="/" element={<StaticSiteView />} />
      <Route path="" element={<Error error="401" />} />
    </Routes>
  );
}
