type StaticFileHelpers = {
  getFullSvgPath: (relativeSvgUrlWithoutExt: string) => string;
};

/**
 * Hook to provide helpers for static files stored on a azure file storage
 */
export default function useStaticFileHelpers(): StaticFileHelpers {
  function getFullSvgPath(relativeImageUrl: string): string {
    return `${process.env.REACT_APP_HEADLESS_CMS_URL}${relativeImageUrl}`;
  }

  return {
    getFullSvgPath
  };
}
